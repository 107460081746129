import React from "react";

const YourOrders = (props) => {
  if (props.portalMode === "SearchResults") {
    return (
      <div>
        <span className="TitleTextOpenSans">Search Results: {props.searchResultsLen}</span>
      </div>
    );
  } else {
    return (
      <div>
        <span className="TitleTextOpenSans">Your Orders</span>
      </div>
    );
  }
};

export default YourOrders;
