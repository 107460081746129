export const GetSingleShipmentByID = function (id, timeOutValue) {
  return new Promise(function (resolve, reject) {
    // let timeOutValue = 100000;
    // let carrier = "acmebumn-testtl1";

    let controller = new AbortController();

    let url =
      "https://api.shippersedge.com/v4/shipment/" +
      id +
      "?include=handlingUnits,status,documents,messages,billing,location";

    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let request = new Request(url, requestOptions);

    if (timeOutValue) {
      setTimeout(() => controller.abort(), timeOutValue);
    }
    fetch(request)
      .then((response) => {
        // console.log(
        //   "debug GetAllShipmentsByLocationCode.js response",
        //   response
        // );
        if (!response.ok) {
          console.error(
            "GetSingleShipmentByID.js error",
            response.statusText,
            response
          );
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((response) => {
        // console.log("getsingle", response);
        resolve(response);
      })
      .catch((err) => {
        console.error("GetSingleShipmentByID API Request failed", err);
        resolve(err);
      });
  });
};

export default GetSingleShipmentByID;
