import React, { useEffect, useState } from "react";
import EmailForm from "./Components/EmailForm";
import InviteCodeForm from "./Components/InviteCodeForm";
import PasswordForm from "./Components/PasswordForm";
import * as QueryString from "query-string";

const SignUp = (props) => {
  // URL Params
  const [urlEmail, seturlEmail] = useState(null);
  const [urlInviteCode, seturlInviteCode] = useState(null);
  //
  const [inviteCode, setinviteCode] = useState(null);
  const [email, setemail] = useState("");
  const [password1, setpassword1] = useState("");
  const [password2, setpassword2] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  //
  const [alertType, setAlertType] = useState("alert alert-light");
  const [alertText, setAlertText] = useState("");
  const [link, setlink] = useState(null);

  useEffect(() => {
    const urlParams = QueryString.parse(props.location.search);
    if (urlParams.invitecode) {
      seturlInviteCode(urlParams.invitecode);
      setinviteCode(urlParams.invitecode);
    }
    if (urlParams.email) {
      seturlEmail(urlParams.email);
      setemail(urlParams.email);
    }
  }, [props.location.search]);

  useEffect(() => {
    if (password1.length > 5 && password2.length > 5) {
      if (password1.localeCompare(password2) === 0) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }
  }, [password1, password2]);

  function buildFormData() {
    return new Promise(function (resolve, reject) {
      const singUpFormData = new FormData();
      singUpFormData.append("SignUp1!", true);
      singUpFormData.append("InviteCode", inviteCode);
      singUpFormData.append("Password", password1);
      singUpFormData.append("Email", email);
      for (var pair of singUpFormData.entries()) {
        console.log("debug singUpFormData", pair[0] + ", " + pair[1]);
      }

      resolve(singUpFormData);
    });
  }

  function checkPasswordsOnSubmit() {
    return new Promise(function (resolve, reject) {
      let result = false;
      if (password1.localeCompare(password2) === 0) {
        if (password1.length > 5) {
          result = true;
        } else {
          StatusSetter(
            "Passwords must be at least 6 characters.",
            "alert alert-danger"
          );
          result = false;
        }
      } else {
        StatusSetter("Passwords do not match.", "alert alert-danger");
        result = false;
      }
      resolve(result);
    });
  }

  function submitSignUpToAPI(formGo, timeOutValue) {
    console.log("submitSignUpToAPI");
    return new Promise(function (resolve, reject) {
      let controller = new AbortController();
      let signal = controller.signal;
      let url =
        "https://www2.shippersedge.com/public/consignee/consigneePortal.cfm?wsdl";
      let request = new Request(url, {
        method: "POST",
        signal,
        body: formGo,
      });
      setTimeout(() => controller.abort(), timeOutValue);
      fetch(request)
        .then((response) => {
          //   console.log("debug UpdateShipmentStatus response", response);
          if (!response.ok) {
            console.error("SignUp.js error", response.statusText, response);
            throw Error(response.statusText);
          }

          console.log("ok");
          return response.json();
        })
        .then((response) => {
          resolve(response.message);
        })
        .catch((err) => {
          console.error("SignUp API Request failed", err);
          resolve("Aborted");
        });
    });
  }

  const SequentalFuction = async () => {
    resetAlertAndLink();
    // Check Passwords
    StatusSetter("Verifying..", "alert alert-secondary");
    const checkPasswordsMatchAndLengthGo = await checkPasswordsOnSubmit();
    if (!checkPasswordsMatchAndLengthGo) {
      return false;
    }

    StatusSetter("Verifying password", "alert alert-secondary");
    const formGo = await buildFormData();
    console.log("formGo", formGo);
    StatusSetter("Submitting Sign Up..", "alert alert-secondary");
    const SignUpGo = await submitSignUpToAPI(formGo, 20000);
    console.log("SignUpGo", SignUpGo);
    if (SignUpGo === "Aborted") {
      const SignUpGo = await submitSignUpToAPI(formGo, 20000);

      if (SignUpGo === "User already has a password") {
        StatusSetter(
          `${email} has already signed up and has a password. If you forgot your password please go to the login page and select "Forgot Password"`,
          "alert alert-warning"
        );
        setlink("https://receiving.shippersedge.com/");
      }

      if (SignUpGo === "Password successfully set") {
        StatusSetter("Sucessfully Signed Up!", "alert alert-success");
        setlink("https://receiving.shippersedge.com/");
      }
      if (SignUpGo === "Aborted") {
        const SignUpGo = await submitSignUpToAPI(formGo, 30000);
        if (SignUpGo === "User already has a password") {
          StatusSetter(
            `${email} has already signed up and has a password. If you forgot your password please go to the login page and select "Forgot Password"`,
            "alert alert-warning"
          );
          setlink("https://receiving.shippersedge.com/");
        }
        if (SignUpGo === "Password successfully set") {
          StatusSetter("Sucessfully Signed Up!", "alert alert-success");
          setlink("https://receiving.shippersedge.com/");
        }
        if (SignUpGo === "Aborted") {
          StatusSetter(
            "Network error, please try again later",
            "alert alert-danger"
          );
        }
      }
    }
    if (SignUpGo === "User already has a password") {
      StatusSetter(
        `${email} has already signed up and has a password. If you forgot your password please go to the login page and select "Forgot Password"`,
        "alert alert-warning"
      );
      setlink("https://receiving.shippersedge.com/");
    }
    if (SignUpGo === "Password successfully set") {
      StatusSetter("Sucessfully Signed Up!", "alert alert-success");
      setlink("https://receiving.shippersedge.com/");
    }
  };

  function StatusSetter(text, type) {
    setAlertText(text);
    setAlertType(type);
  }

  function resetAlertAndLink() {
    StatusSetter("", "alert alert-light");
    setlink(null);
  }

  function AlertBox() {
    return (
      <div className={`${alertType}`} role="alert">
        {alertText} &nbsp; &nbsp;{" "}
        <span>{link ? <a href={link}>Link.</a> : null}</span>
      </div>
    );
  }

  return (
    <div className="container">
      <header className="App-header TitleText">
        <img src="shippersedge-logo.png" alt="logo" height="100px" />
        Consingee Portal Sign Up
      </header>
      <br />
      <div className="row">
        <div className="col">
          <p>Welcome to the Consignee Portal.</p>
        </div>
      </div>
      {urlEmail ? null : <EmailForm setemail={setemail} />}
      {urlInviteCode ? null : <InviteCodeForm setinviteCode={setinviteCode} />}
      <PasswordForm setpassword1={setpassword1} setpassword2={setpassword2} />
      {passwordMatch ? (
        <p style={{ color: "green" }}>Passwords Match!</p>
      ) : (
        <p>&nbsp;</p>
      )}
      <div className="row">
        <div className="col-2">
          <button
            className="btn btn-primary"
            onClick={() => SequentalFuction()}
          >
            {" "}
            Submit
          </button>
        </div>
        <div className="col-10">
          <AlertBox />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default SignUp;
