import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import GetSingleShipmentByID from "../../Services/API/GetSingleShipmentByID"
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps"
import { format } from "date-fns"
import BeautyStars from 'beauty-stars'

const OrderView = () => {
    let { orderGUID } = useParams()
    const [order, setOrder] = useState(null)
    const [eta, setEta] = useState(new Date())
    const [stars, setStars] = useState(0)
    const [feedback, setFeedback] = useState(null)
    const [showFeedbackDiv, setShowFeedbackDiv] = useState('d-none')
    const [showFeedbackDeliveredDiv, setShowFeedbackDeliveredDiv] = useState('d-none')

    useEffect(() => {
        if (!order)
            getShipmentByGUID(orderGUID)
    })

    const getShipmentByGUID = async orderGUID => {
        const shipment = await GetSingleShipmentByID(orderGUID)
        console.log(shipment)
        if (shipment.status.location?.duration) {
            setEta(new Date(new Date().getTime() + shipment.status.location.duration * 60000))
        } else {
            setEta(null)
        }
        setOrder(shipment)
    }

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
        <div>
            {/* Center the map keeping bounds for the two markers below and set the zoom to an appriate zoom based on the points */}
            <GoogleMap GoogleMap
                defaultOptions={{ fullscreenControl: false, zoomControl: true, mapTypeControl: false, streetViewControl: false, panControl: true, draggable: false }}
                center={{ lat: (Number(props.order?.consignee?.coordinates?.latitude) + Number(props.order?.status?.location?.latitude)) / 2, lng: (Number(props.order?.consignee?.coordinates?.longitude) + Number(props.order?.status?.location?.longitude)) / 2 }}
                zoom={12}
                options={{mapId: "2121dadd53a50821"}}
            >
                <Marker
                    position={{ lat: +props.order?.consignee?.coordinates?.latitude, lng: +props.order?.consignee?.coordinates?.longitude }}
                />
                <Marker
                    icon={{
                        url: "https://img.icons8.com/color/48/000000/delivery.png",
                        scaledSize: new window.google.maps.Size(48, 48),
                    }}
                    position={{ lat: props.order?.status?.location?.latitude, lng: props.order?.status?.location?.longitude }}
                />
            </GoogleMap>
        </div>
    ))
    const saveRating = (value) => {
        setStars(value)
        setShowFeedbackDiv('d-inline')
    }

    const saveFeedback = (value) => {
        setFeedback(value)
    }

    const submitFeedback = () => {
        setShowFeedbackDeliveredDiv('d-inline')
    }
    // What is the bootstrap class for small font?

    if (!order) {
        return (
            <div>...Loading</div>
        )
    } else {
        return (
            <div className="container" style={{ maxWidth: "540px" }}>
                <h1><img src="https://www2.shippersedge.com/images/logos/acme.jpg" className="m-2" height="50" /></h1>
                <div class="row justify-content-center mt-2">
                    <div className="card bg-light ml-3 mr-3 mt-0 w-100">
                        <div className="card-header" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h3 className="pl-1 pt-0 m-0">{order.status.code !== 'DEL' ? "You are the next stop!" : "Delivered!"}</h3>
                            <div className="pl-1 pt-1 m-0 font-size-md">
                                {
                                    order.status.code !== 'DEL' && eta
                                    ? `Estimated Arrival: ${Math.round(order.status.location?.duration)} min (${format(eta, 'p')})` 
                                    : "Your order was delivered Today at 11:07 AM - "
                                }
                                {
                                    (order.status.code === 'DEL') 
                                    ? <a href="#">Where?</a> 
                                    : null
                                }
                            </div>
                        </div>

                        <div className="card-body p-0">
                            {
                                (order.status.code === 'DEL')
                                    ? <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ height: "250px" }}>
                                        <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ width: "350px" }}>
                                            <h3>How did we do?</h3>
                                            <BeautyStars
                                                className="m-0"
                                                value={stars}
                                                activeColor="#FFE800"
                                                onChange={value => saveRating(value)}
                                            />
                                            <div className={showFeedbackDiv}>
                                                <input type="text" name="feedback" id="feedback" placeholder="Please give us feedback here" className="mt-3 p-2" onChange={value => saveFeedback(value)} style={{ width: "15rem", fontSize: "1rem" }} />
                                                <button type="button" class="btn btn-secondary ml-1 p-2" style={{ borderWidth: 2, verticalAlign: "bottom" }} onClick={submitFeedback}>Submit</button>
                                                {/* <select name="feedback" id="feedback" className="m-2 p-2" onChange={value => saveFeedback(value)} style={{ fontSize: "1.2rem" }}>
                                                    <option>--- Please Provide Feedback ---</option>
                                                    <option>Delivery was late</option>
                                                    <option>Delivery was late</option>
                                                </select> */}
                                            </div>
                                            <div className={showFeedbackDeliveredDiv} style={{ fontSize: "1.1rem" }}>
                                                Thank you for your feedback!
                                            </div>
                                        </div>
                                    </div>
                                    : <MapWithAMarker
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA6PNkY8Fb4CNiH-xQKMQoRNUnjxUGnL-s&v=3.exp&libraries=geometry,drawing,places"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `300px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        order={order}
                                    />
                            }
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center mt-2 h-25 mb-5">
                    <div className="col-12 ml-xs-1 mr-xs-1 pb-2 mt-xs-3 mt-md-1">
                        <div className="card bg-light w-300 h-100">
                            <div className="card-header h6">Order Information</div>
                            <div className="card-body p-3" style={{ fontSize: "0.75rem" }}>
                                <div>Order Number: {order.usercontrol}</div>
                                <div>Scheduled Delivery: 9 AM - 11 AM</div>
                                <div>Address: {order.consignee.address.line1} {order.consignee.address.line2} {order.consignee.address.city}, {order.consignee.address.state} {order.consignee.address.zip}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 ml-xs-1 mr-xs-1 pb-2 pt-xs-5 mt-xs-3 mt-md-1">
                        <div className="card bg-light w-300 h-100">
                            <div className="card-header h6">Manage Delivery</div>
                            <div className="card-body p-3" style={{ fontSize: "1rem" }}>
                                {(order.status.code !== 'DEL')
                                    ? <div>
                                        <div><a href="#">Update Delivery Instructions</a></div>
                                        <div><a href="#">Reschedule Delivery</a></div>
                                    </div>
                                    : null}
                                <div><a href="#">Contact Acme Manufacturing</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}

export default OrderView
