export const GetAllShipmentsByLocationCode = function (
  locationcode,
  timeOutValue
) {
  return new Promise(function (resolve, reject) {
    // let timeOutValue = 100000;
    // let carrier = "acmebumn-testtl1";

    let controller = new AbortController();

    let url =
      "https://api.shippersedge.com/v4/shipment/?locationcode=" +
      locationcode +
      "&include=handlingUnits,status,documents,messages,billing,&limit=1000";

    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");
    // old hkkRPVPKlucmQpyYNSZ4
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let request = new Request(url, requestOptions);

    if (timeOutValue) {
      setTimeout(() => controller.abort(), timeOutValue);
    }
    fetch(request)
      .then((response) => {
        // console.log(
        //   "debug GetAllShipmentsByLocationCode.js response",
        //   response
        // );
        if (!response.ok) {
          console.error(
            "GetAllShipmentsByLocationCode.js error",
            response.statusText,
            response
          );
          throw Error(response.statusText);
        }
        // console.log("getShipmentsByLocationCode.js", response);
        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.error("GetAllShipmentsByLocationCode API Request failed", err);
        resolve(err);
      });
  });
};
