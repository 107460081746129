import React, { useState, useContext, useEffect } from "react";
import BootStrapModal from "react-bootstrap/Modal";
import SubmitUpdate from "./Components/SubmitUpdate/SubmitUpdate";
import DeliveredQ001 from "./Components/FormItemsQs/DeliveredQ001";
import DamagedQ001 from "./Components/FormItemsQs/DamagedQ001";
import Notes001 from "./Components/FormItemsQs/Notes001";
import HUPackagesMod from "./Components/HUPackagesMod";
import UserContext from "../../Services/Context/UserContext";
import DocumentUpload from "./Components/FormItemsQs/DocumentUpload";
import ExistingDocuments from "./Components/ExistingDocuments";
import DeliveryDateQ001 from "./Components/FormItemsQs/DeliveryDateQ001";

const PrimaryModal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Question States
  const [deliveredQ001, setDeliveredQ001] = useState(null);
  const [damaged001, setdamaged001] = useState(null);
  const [notes001, setNotes001] = useState("");
  const [deliveryDateQ001, setDeliveryDateQ001] = useState("");

  // File type and file upload
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState(null);

  // User Conext data
  const user = useContext(UserContext);

  // Set inital local values (for now there are so few values this works fine, later we can create a local shipment object, etc.)
  useEffect(() => {
    if (props.shipment.consignee.dates.actualDelivery) {
      setDeliveryDateQ001(props.shipment.consignee.dates.actualDelivery);
    }
  }, [props.shipment.consignee.dates.actualDelivery])


  // Build FormData Shipment Update (not file upload)
  const BuildFormData = () => {
    return new Promise((resolve) => {
      const formData = new FormData();
      // UpdateShipment! is required
      formData.append("Updateshipment!", true);
      // ShipmentId is required
      formData.append("shipmentId", props.shipment.id);
      // Status related answers, status is required
      if (deliveredQ001 === "yes") {
        formData.append("status", "DEL");
      } else {
        formData.append("status", props.shipment.status.code);
      }
      // Delivered date
      if (deliveryDateQ001 !== props.shipment.consignee.dates.actualDelivery) {
        formData.append("deldate", deliveryDateQ001);
      }
      // Damaged
      if (damaged001 === null || damaged001 === "no") {
        // Don't do anything if no damage reported
      } else if (damaged001 === "yes") {
        formData.append(
          "usernotes",
          "The customer reported this shipment was damaged via Customer Portal."
        );
        formData.delete("status");
        formData.append("status", "DEL-CP");
      }
      // Send who updated it
      formData.append("updatedBy", user.name);
      // FormData Debug
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      // Return Form Data
      resolve(formData);
    });
  };

  const BuildFormDataDocumentUpload = () => {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("ShipmentId", props.shipment.id);
      if (fileType) {
        formData.append("fileType", fileType);
      }
      if (file) {
        formData.append("file", file, fileType);
      }
      // formData.append('contactEmail', user.name)
      // formData Debug
      // console.log("file formdata", formData);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      resolve(formData);
    });
  };

  // File change handler
  const fileChangeHandler = (event) => {
    setFileName(event.target.files[0].name);
    setFile(event.target.files[0]);
  };
  return (
    <>
      <button className="btn btn-success" onClick={handleShow}>
        {props.btnTxt}
      </button>

      <BootStrapModal show={show} onHide={handleClose} size="xl">
        <BootStrapModal.Header closeButton>
          <BootStrapModal.Title>
            Order: {props.shipment.usercontrol}
          </BootStrapModal.Title>
        </BootStrapModal.Header>

        <div className="modal-body">
          <div className="card-subtitle text-muted">
            <HUPackagesMod handlingUnits={props.shipment.handlingUnits} />
          </div>
          {/* 
            Questions and forms:
          */}
          <DeliveredQ001 setDeliveredQ001={setDeliveredQ001} />
          <DeliveryDateQ001 actualDelivery={props.shipment.consignee.dates.actualDelivery} deliveryDateQ001={deliveryDateQ001} setDeliveryDateQ001={setDeliveryDateQ001} />
          <DamagedQ001 setdamaged001={setdamaged001} />
          <Notes001
            setNotes001={setNotes001}
            notes001={notes001}
            messages={props.shipment.messages}
          />

          <DocumentUpload
            fileChangeHandler={fileChangeHandler}
            fileName={fileName}
            setFileType={setFileType}
          />
          <ExistingDocuments documents={props.shipment.documents} />
        </div>
        <div className="modal-footer PrimaryModalFooterExtraHeight">
          <SubmitUpdate
            onClick={() => setShow()}
            BuildFormData={BuildFormData}
            BuildFormDataDocumentUpload={BuildFormDataDocumentUpload}
            notes001={notes001}
            messages={props.shipment.messages}
            GetSingleShipmentRequestDashboard={
              props.GetSingleShipmentRequestDashboard
            }
            shipmentBOL={props.shipment.id}
            user={user}
          />
        </div>
      </BootStrapModal>
    </>
  );
};

export default PrimaryModal;
